import { User } from "firebase/auth";
import { navigate } from "gatsby";
import React, { useContext, useEffect } from "react";
import { USER_ROLES } from "shared-models/build/constants";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { getCustomClaims } from "../../util/util";

const LoginRedirect = () => {
    const user = useContext(FirebaseAuthContext);

    useEffect(() => {
        if (user === null) return;
        handleLoginRedirect(user);
    }, [user]);

    const handleLoginRedirect = async (firebaseUser: User) => {
        const idTokenResult = await getCustomClaims(firebaseUser);
        if (idTokenResult.claims[USER_ROLES.TOUR_OPERATOR]) {
            navigate("/operator");
        } else {
            navigate("/user");
        }
    }

    return (
        <>
            Loading ...
        </>
    )
}

export default LoginRedirect;